import React from 'react';
import { makeStyles, Theme, Paper } from '@material-ui/core';

import { Content, Header, Page } from '@backstage/core-components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  h2: {
    fontSize: 'clamp(2.5rem, 6vw, 3.75rem)',
    fontWeight: theme.typography.h2.fontWeight,
    marginBottom: theme.typography.h2.marginBottom,
  },
  // NOTE: a comfortable reading-length has a max of '70ch'
  p: {
    fontSize: 'clamp(1rem, 4vw, 1.25rem)',
    marginBottom: '1em',
    maxWidth: '70ch',
  },
  a: {
    color: theme.palette.primary.main,
  },
  hr: {
    borderWidth: 0.5,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '1400px',
  },
}));

const GettingStartedPage = () => {
  const classes = useStyles();

  return (
    <Page themeId="getting-started">
      <Header title="Getting Started" />
      <Content>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Registration Process in Backstage</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            To automatically register an entry in Backstage, a GitHub project
            must meet the following criteria:
          </p>
          <ul className={classes.p}>
            <li className={classes.p}>
              <strong>GitHub Organization Membership:</strong> The project must
              be a member of the "bayer-int" GitHub Organization.
            </li>
            <li className={classes.p}>
              <strong>GitHub Topic:</strong> The project should have the
              "backstage-candidate" GitHub topic associated with it.
            </li>
            <li className={classes.p}>
              <strong>Catalog Information File:</strong> The "catalog-info.yaml"
              file must be included in the project's root directory.
            </li>
            <li className={classes.p}>
              <strong>Branch Requirement:</strong> The same "catalog-info.yaml"
              file must also be in a Git branch named "main."
            </li>
          </ul>
          <p className={classes.p}>
            The auto-discovery process runs and updates every 15 minutes. Once
            this process has been completed, your catalog entry will become
            visible within Backstage. If you wish not to wait 15 minutes, follow
            the Manual Registration Steps.
          </p>
        </Paper>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Manual Registration</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            To manually register, navigate to the "Register" option in the side
            navigation. Enter the complete path to the "catalog-info.yaml" file
            into the "Select URL" field. Then, proceed by following the prompts
            displayed on the Registration screen.
          </p>
          <p className={classes.p}>
            If a GitHub project does not utilize a branch named "main," manual
            registration in Backstage is still possible. To do this, provide the
            path to the "catalog-info.yaml" file.
          </p>
          <p className={classes.p}>
            For detailed information regarding the default data structure and
            semantics of catalog entities for the "catalog-info.yaml" file,
            please{' '}
            <a
              className={classes.a}
              href="https://backstage.io/docs/features/software-catalog/descriptor-format/#overall-shape-of-an-entity"
              target="_blank"
            >
              consult the Backstage documentation
            </a>
            .
          </p>
          <p className={classes.p}>
            For an example "catalog-info.yaml" file, you can{' '}
            <a
              className={classes.a}
              href="/docs/default/component/backstage/getting-started/add-components/#steps"
            >
              refer to the local Backstage TechDocs
            </a>
            .
          </p>
          <p className={classes.p}>
            Consider adding{' '}
            <a
              className={classes.a}
              href="https://github.com/bayer-int/backstage-yaml-validator"
              target="_blank"
            >
              Bayer's custom YAML validator
            </a>{' '}
            as a development dependency to ensure that the "
            <strong>catalog-info.yaml</strong>" file is well-formed and meets
            the schema standards.
          </p>
        </Paper>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Next Steps</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            <a className={classes.a} href="/help">
              Need assistance?
            </a>{' '}
            Bayer has a dedicated team ready to help! After registering in the
            Backstage catalog, consider the following options:
          </p>
          <ul className={classes.p}>
            <li className={classes.p}>
              <strong>Join the Backstage Team on Teams:</strong> Engage with the
              Backstage team and initiate discussions. Most Backstage
              announcements and support activities are conducted on{' '}
              <a
                className={classes.a}
                href="https://teams.microsoft.com/l/channel/19%3aZT6ymk0Zm67woY2At6-IlUTAuwmQtT-ZFYKogk5zSuU1%40thread.tacv2/General?groupId=a7d09a88-d5a3-47a4-baae-7220822fc668&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78"
                target="_blank"
              >
                Teams
              </a>
              .
            </li>
            <li className={classes.p}>
              <strong>Explore Templates:</strong> Discover how{' '}
              <a
                className={classes.a}
                href="https://backstage.io/docs/features/software-templates/writing-templates/"
                target="_blank"
              >
                Templates
              </a>{' '}
              can expedite your Team's time-to-market. Quickly create reusable
              boilerplate code for your Team's use.
            </li>
            <li className={classes.p}>
              <strong>Leverage TechDocs:</strong> Consider transitioning your
              technical documentation into a GitHub repository flow. Take
              advantage of{' '}
              <a
                className={classes.a}
                href="https://backstage.io/docs/features/techdocs/"
                target="_blank"
              >
                TechDocs
              </a>{' '}
              to make your documentation more discoverable.
            </li>
          </ul>
          <p className={classes.p}>
            Once your project is registered, make sure to inform users that they
            can find it on Backstage!
          </p>
        </Paper>
      </Content>
    </Page>
  );
};

export const gettingStartedPage = <GettingStartedPage />;
