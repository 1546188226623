import React from 'react';
import { makeStyles, Theme, Paper } from '@material-ui/core';

import { Content, Header, Page } from '@backstage/core-components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  h2: {
    fontSize: 'clamp(2.5rem, 6vw, 3.75rem)',
    fontWeight: theme.typography.h2.fontWeight,
    marginBottom: theme.typography.h2.marginBottom,
    maxWidth: '70ch',
  },
  h6: {
    fontSize: 'clamp(1.25rem, 5vw, 1.875rem)',
    fontWeight: theme.typography.h6.fontWeight,
    marginBottom: 0,
    marginTop: '1.5em',
  },
  // NOTE: a comfortable reading-length has a max of '70ch'
  p: {
    fontSize: 'clamp(1rem, 4vw, 1.25rem)',
    marginBottom: '1em',
    marginLeft: 0,
    maxWidth: '70ch',
  },
  a: {
    color: theme.palette.primary.main,
  },
  hr: {
    borderWidth: 0.5,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '1400px',
  },
}));

const HelpPage = () => {
  const classes = useStyles();

  return (
    <Page themeId="help">
      <Header title="Help" />
      <Content>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Get in Touch with the Bayer Team</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            You can easily reach out to the{' '}
            <a
              className={classes.a}
              href="https://teams.microsoft.com/l/channel/19%3aZT6ymk0Zm67woY2At6-IlUTAuwmQtT-ZFYKogk5zSuU1%40thread.tacv2/General?groupId=a7d09a88-d5a3-47a4-baae-7220822fc668&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78"
              target="_blank"
            >
              Bayer Backstage Team through Microsoft Teams
            </a>{' '}
            by locating the "Backstage" Team. Join the Team to initiate a new
            conversation, where you can:
            <ul className={classes.p}>
              <li className={classes.p}>Request assistance or guidance</li>
              <li className={classes.p}>
                Share feedback on your Backstage experience
              </li>
              <li className={classes.p}>
                Seek support for any issues you encounter
              </li>
              <li className={classes.p}>
                Offer suggestions to enhance Backstage
              </li>
            </ul>
          </p>
          <h3 className={classes.h6}>Interested in a Demo?</h3>
          <p className={classes.p}>
            If you're interested in a demonstration for your Team or group, the
            Bayer Backstage Team is more than happy to accommodate. They can
            arrange a quick call or share a slide deck to showcase Backstage's
            capabilities.
          </p>
        </Paper>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Frequently Asked Questions (FAQs)</h2>
          <hr className={classes.hr} />
          <dl>
            <dt className={classes.h6}>
              How frequently are new components from GitHub discovered?
            </dt>
            <dd className={classes.p}>
              New components from GitHub are discovered every 15 minutes.
            </dd>

            <dt className={classes.h6}>
              How often is the GitHub Organization data refreshed?
            </dt>
            <dd className={classes.p}>
              Presently, the data for the GitHub Organization is refreshed every
              12 hours. Our goal is to shorten this interval in the future.
            </dd>

            <dt className={classes.h6}>Can we track the usage of templates?</dt>
            <dd className={classes.p}>
              This remains an area for potential future research and
              enhancement.
            </dd>
          </dl>
        </Paper>
      </Content>
    </Page>
  );
};

export const helpPage = <HelpPage />;
