import React from 'react';
import { makeStyles, Theme, Paper } from '@material-ui/core';

import { Content, Header, Page } from '@backstage/core-components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  h2: {
    fontSize: 'clamp(2.5rem, 6vw, 3.75rem)',
    fontWeight: theme.typography.h2.fontWeight,
    marginBottom: theme.typography.h2.marginBottom,
  },
  // NOTE: a comfortable reading-length has a max of '70ch'
  p: {
    fontSize: 'clamp(1rem, 4vw, 1.25rem)',
    marginBottom: '1em',
    maxWidth: '70ch',
  },
  a: {
    color: theme.palette.primary.main,
  },
  hr: {
    borderWidth: 0.5,
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '1400px',
  },
}));

const WelcomePage = () => {
  const classes = useStyles();

  return (
    <Page themeId="welcome">
      <Header title="Welcome" />
      <Content>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>What is Backstage?</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            <a
              className={classes.a}
              href="https://backstage.io/docs/overview/what-is-backstage/"
              target="_blank"
            >
              Backstage
            </a>{' '}
            is a developer portal offering users a central place to find,
            understand, and use the software they need more quickly. It
            aggregates our software components, associated infrastructure
            tooling, and documentation within a single interface.
          </p>

          <p className={classes.p}>
            Here are some of the key features of Backstage:
          </p>
          <ul className={classes.p}>
            <li className={classes.p}>
              <strong>Software catalog:</strong> The software catalog is a
              central repository for all the software components, including
              microservices, libraries, data pipelines, websites, and ML models.{' '}
            </li>
            <li className={classes.p}>
              <strong>Software templates:</strong> Software templates are
              pre-configured projects that you can use to quickly spin up new
              projects. They include all the necessary tooling and configuration
              to get started immediately.
            </li>
            <li className={classes.p}>
              <strong>TechDocs:</strong> TechDocs is a tool for creating,
              maintaining, and finding technical documentation. It uses a
              "docs-like code" approach, which makes it easy to keep your
              documentation up-to-date and in sync with your code.
            </li>
            <li className={classes.p}>
              <strong>Plugins:</strong> Backstage has a growing ecosystem of
              open-source plugins that further expand its customizability and
              functionality.
            </li>
          </ul>
          <p className={classes.p}>
            <a
              href="https://backstage.io/blog/2022/03/16/backstage-turns-two/#out-of-the-sandbox-and-into-incubation"
              target="_blank"
            >
              Backstage was initially built by Spotify and then donated to the
              CNCF.
            </a>{' '}
            Backstage has been adopted by many companies, including Netflix,
            American Airlines, LinkedIn, HP, Caribou, and Snyk.
          </p>

          <p className={classes.p}>
            Backstage unifies all your infrastructure tooling, services, and
            documentation to create a streamlined development environment from
            end to end.{' '}
            <a
              className={classes.a}
              href="https://backstage.io/blog/2022/03/16/backstage-turns-two/#out-of-the-sandbox-and-into-incubation"
              target="_blank"
            >
              Backstage was originally built by Spotify and then donated to the
              CNCF.
            </a>
          </p>
        </Paper>
        <Paper className={classes.paper}>
          <h2 className={classes.h2}>Why use Backstage?</h2>
          <hr className={classes.hr} />
          <p className={classes.p}>
            One place for everything. Accessible to everyone.
          </p>
          <ul className={classes.p}>
            <li className={classes.p}>
              <strong>Improved developer experience:</strong> Backstage provides
              a centralized location for developers to access all the
              information they need for their work. This includes documentation,
              code repositories, CI/CD pipelines, monitoring and observability
              tools, and more. It simplifies the developer experience by
              consolidating these resources in one place.
            </li>
            <li className={classes.p}>
              <strong>Improved developer productivity:</strong> Backstage helps
              teams find, understand, and use the software they need more
              quickly. This can save developers time and frustration and help
              them to be more productive.
            </li>
            <li className={classes.p}>
              <strong>Faster time to market:</strong> Backstage can help teams
              to ship new features and products faster in a standard way.{' '}
            </li>
            <li className={classes.p}>
              <strong>Collaboration:</strong> Backstage fosters collaboration
              among development teams and other stakeholders. It provides a
              platform for sharing knowledge, documentation, and insights,
              leading to better collaboration.
            </li>
            <li className={classes.p}>
              <strong>Extensibility:</strong> Backstage is highly extensible,
              allowing organizations to integrate custom tools and services into
              the platform. This means you can tailor Backstage to your
              development and infrastructure needs.
            </li>
          </ul>
        </Paper>
      </Content>
    </Page>
  );
};

export const welcomePage = <WelcomePage />;
