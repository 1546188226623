import React from 'react';
import {
    HomePageCompanyLogo,
    HomePageStarredEntities,
    TemplateBackstageLogo,
    HeaderWorldClock,
    ClockConfig,
    HomePageToolkit,
    HomePageTopVisited,
    HomePageRecentlyVisited,
} from '@backstage/plugin-home';

import { Header } from '@backstage/core-components';
import { Content, Page } from '@backstage/core-components';
import {
    HomePageSearchBar,
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import { GettingStartedLogo } from "./GettingStartedLogo";
import { CloudLogo } from "./CloudLogo";
import { LearningLogo } from "./LearningLogo";
import { DevSecOpsLogo } from "./DevSecOpsLogo";
import MaterialButton from "@material-ui/core/Button";
import { BazaarOverviewCard } from '@backstage/plugin-bazaar';

const useStyles = makeStyles(theme => ({
    searchBar: {
        display: 'flex',
        maxWidth: '60vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        padding: '8px 0',
        borderRadius: '50px',
        margin: 'auto',
    },
    searchBarOutline: {
        borderStyle: 'none',
    },
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const { svg, path, container } = useLogoStyles();

    const links = [
        {
            url: 'https://docs.int.bayer.com/cloud/crop-science/development/onboarding/',
            label: 'Onboarding',
            icon: <GettingStartedLogo/>,
        },
        {
            url: 'https://docs.int.bayer.com/cloud/?utm_source=legacy-redirect&utm_medium=url',
            label: 'Cloud Docs',
            icon: <CloudLogo/>,
        },
        {
            url: 'https://devsecops-maturity.agro.services/',
            label: 'DevSecOps Maturity',
            icon: <DevSecOpsLogo/>,
        },
        {
            url: 'https://bayergroup.sharepoint.com/sites/ProductPlatforms/SitePages/Developer-Blog.aspx?csf=1&web=1&e=4THgLM',
            label: 'Developer Blog',
            icon: <LearningLogo/>,
        },
    ]

    const clockConfigs: ClockConfig[] = [
        {
            label: 'Bengaluru',
            timeZone: 'Asia/Kolkata',
        },
        {
            label: 'Leverkusen',
            timeZone: 'Europe/Berlin',
        },
        {
            label: 'Warsaw',
            timeZone: 'Europe/Warsaw',
        },
        {
            label: 'São Paulo',
            timeZone: 'America/Sao_Paulo',
        },
        {
            label: 'St. Louis',
            timeZone: 'America/Chicago',
        },
    ];

    const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Content>
                    <Grid container item xs={12}>
                        <Header title="Time in our offices" pageTitleOverride="Home">
                            <HeaderWorldClock clockConfigs={clockConfigs}
                                                  customTimeFormat={timeFormat}
                            />
                        </Header>
                    </Grid>
                    <Grid container justifyContent="center" spacing={6}>
                        <HomePageCompanyLogo
                            className={container}
                            logo={<TemplateBackstageLogo classes={{ svg, path }} />}
                        />
                        <Grid container item xs={12} alignItems="center" direction="row">
                            <HomePageSearchBar
                                classes={{ root: classes.searchBar }}
                                InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
                                placeholder="Search Backstage"
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={4}>
                                <HomePageStarredEntities />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <HomePageTopVisited />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <HomePageRecentlyVisited />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={4}>
                                <HomePageToolkit
                                    title="Toolkit"
                                    tools={links}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <BazaarOverviewCard title='Latest InnerSource Projects' order='latest'/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                        <MaterialButton target="_blank" href="https://forms.office.com/e/LU34cTELNm" color="default" variant="outlined">
                            Leave Feedback
                        </MaterialButton>
                    </Grid>
                </Content>
            </Page>
        </SearchContextProvider>
    );
};