import {datadogRum} from '@datadog/browser-rum';
import { useEffect } from 'react';
import {useUserProfile} from '@backstage/plugin-user-settings';

export function useDataDogRum() {
    const { profile } = useUserProfile();

    useEffect(() => {
        datadogRum.init({
            clientToken: 'pub3743e17ec10b31b6bd274bae82b604ce',
            applicationId: 'd2a1c6c9-048e-4071-a0e9-fdc19e71136d',
            site: 'datadoghq.com',
            service: 'backstage',
            env: 'pilot',
            sampleRate: 100,
            sessionReplaySampleRate: 100, // if not included, the default is 100
            trackResources: true,
            trackLongTasks: true,
            trackInteractions: true
        });
        datadogRum.setUser({
            id:  profile.email,
            name: profile.displayName,
            email: profile.email
        });
    });
}
